(() => {
  const handleHeader = () => {
    let $brandsMenu: HTMLElement,
      $brandsMenuLink: HTMLElement,
      $mainMenuToggle: HTMLElement,
      $mainMenu: HTMLElement,
      $header: HTMLElement,
      $headerWrapper: HTMLElement,
      lastScrollTop = 0,
      currentScrollTop = 0,
      headerHeight: number,
      winWidth = window.innerWidth,
      $isiNavigateLinks: NodeListOf<HTMLAnchorElement>

    // hides the dropdown for a given element
    const hideDropdown = ($el: HTMLElement) => {
      $el?.classList.remove('active', 'show-overflow');
      document.removeEventListener('click', handleClickOutsideElement);
    };

    // shows the dropdown for a given element
    const showDropdown = ($el: HTMLElement) => {
      $el?.classList.add('active');
      $el?.addEventListener(
        'transitionend',
        () => {
          $el?.classList.add('show-overflow');
        },
        {
          once: true,
        }
      );

      // when document is clicked hide any opened menus
      document.addEventListener('click', handleClickOutsideElement);
    };

    // Function to handle clicks outside of the element
    const handleClickOutsideElement = event => {
      // If click outside the header
      if (
        !$header?.contains(event.target) &&
        $mainMenu?.classList.contains('active')
      ) {
        hideDropdown($mainMenu);
      }

      // If click outside the brands dropdown
      if (
        !$brandsMenu?.contains(event.target) &&
        $brandsMenu?.classList.contains('active')
      ) {
        hideDropdown($brandsMenu);
      }
    };

    const handleScroll = () => {
      currentScrollTop = window.scrollY || document.documentElement.scrollTop;

      // Compared the current scroll position with the last scroll position
      if (currentScrollTop > lastScrollTop) {
        if (window.scrollY >= headerHeight) {
          $headerWrapper.classList.add('hide');
        }
      } else if (currentScrollTop < lastScrollTop) {
        $headerWrapper.classList.remove('hide');
      }

      // Update the last scroll position
      lastScrollTop = currentScrollTop;
    };

    // appending events to the custom triggers, and clicking a corresponding tab trigger programmatically.
    const appendEvents = () => {
      // Brands dropdown
      $brandsMenuLink?.addEventListener('click', e => {
        e.preventDefault();
        if ($brandsMenu?.classList.contains('active')) {
          hideDropdown($brandsMenu);
        } else {
          showDropdown($brandsMenu);
        }
      });

      // Main dropdown
      $mainMenuToggle?.addEventListener('click', () => {
        // Close the brands dropdown
        if ($brandsMenu?.classList.contains('active')) {
          hideDropdown($brandsMenu);
          return;
        }

        if ($mainMenu?.classList.contains('active')) {
          hideDropdown($mainMenu);
        } else {
          showDropdown($mainMenu);
        }
      });

      $isiNavigateLinks.forEach($el => {
        $el.addEventListener('click', () => {
          // When click on #ISI navigate link, close main menu if opened
          if ($mainMenu?.classList.contains('active')) {
            hideDropdown($mainMenu);
          }
        });
      });

      if ($header) {
        window.addEventListener('scroll', handleScroll);
      }

      window.addEventListener('resize', () => {
        const curWidth = window.innerWidth;
        if (curWidth !== winWidth) {
          winWidth = curWidth;
          headerHeight = $headerWrapper?.clientHeight || 180;
        }
      });
    };

    // initializing variables used across this file
    const initVariables = () => {
      $header = document.querySelector('.header') as HTMLElement;
      $headerWrapper = $header?.closest(
        'header.experiencefragment'
      ) as HTMLElement;

      // brand menu and its link
      $brandsMenu = $headerWrapper?.querySelector(
        '.header__top-navbar-container-expand-brands'
      ) as HTMLElement;
      $brandsMenuLink = $brandsMenu?.querySelector('p a') as HTMLAnchorElement;

      // main menu toggler and the menu
      $mainMenuToggle = $headerWrapper?.querySelector(
        '.header__main-navbar-menu-btn'
      ) as HTMLElement;
      $mainMenu = $headerWrapper?.querySelector(
        '.header__main-navbar-container-head-menu'
      ) as HTMLElement;

      $isiNavigateLinks = document.querySelectorAll('a[href="#ISI"]') as NodeListOf<HTMLAnchorElement>;

      headerHeight = $headerWrapper.clientHeight || 180;
    };

    initVariables();

    //@ts-ignore
    if ($header) {
      appendEvents();
    }
  };

  if (document.readyState === 'loading') {
    document.addEventListener('DOMContentLoaded', handleHeader);
  } else {
    handleHeader();
  }
})();
