/**
 * Creates a debounced function that delays invoking the provided function until at least ms milliseconds have elapsed.
 * @param func Function to debounce
 * @param wait Delay to trigger function in ms (default: 300)
 * @returns (function)
 */
export const debounce = (func: Function, wait: number = 100) => {
    let timer: ReturnType<typeof setTimeout>;
    return function (this: any, ...args: any[]) {
      clearTimeout(timer);
      timer = setTimeout(() => func.apply(this, args), wait);
    };
  };