(() => {
  const FORM_HAS_ERROR_CLASS_NAME = 'form-has-error';
  const ZIP_CODE_INPUT_SELECTOR = "input[name='zipCode']";
  const FIND_SURGEON_URL_SEARCH_KEY = 'location';
  const FIND_SURGEON_CONTAINER_SELECTOR = '.patientfi__find-surgeon';

  const findInstanceOf = <T extends HTMLElement>(
    selector,
    isInstanceOf: new (...args: Array<any>) => T,
    searchOn: HTMLElement | Document = document
  ): T | null => {
    const element = searchOn.querySelector(selector);
    if (element instanceof isInstanceOf) {
      return element;
    }
    return null;
  };

  const findInstancesOf = <T extends HTMLElement>(
    selector,
    isInstanceOf: new (...args: Array<any>) => T,
    searchOn: HTMLElement | Document = document
  ): Array<T> => {
    const res: Array<T> = [];
    const elements = searchOn.querySelectorAll(selector);
    for (let i = 0; i < elements.length; i++) {
      const element = elements[i];
      if (element instanceof isInstanceOf) {
        res.push(element);
      }
    }
    return res;
  };

  // making sure that the input elements inside the form are accepting only numbers
  // appending error message class to the form when input has error to show error message
  const initInput = (input: HTMLInputElement) => {
    let form: HTMLFormElement | null = null;
    const maybeForm = input.closest('form');
    if (maybeForm instanceof HTMLFormElement) {
      form = maybeForm;
    }
    let timeoutId: number | null = null;
    const onInput = () => {
      input.value = input.value.replace(/[^0-9.]/g, '');
      if (timeoutId !== null) {
        window.clearTimeout(timeoutId);
      }

      // timeout to make sure that the inputs `has-error` class is applied
      timeoutId = window.setTimeout(() => {
        if (form === null) {
          return;
        }
        if (input.closest('.has-error') !== null) {
          form.classList.add(FORM_HAS_ERROR_CLASS_NAME);
        } else {
          form.classList.remove(FORM_HAS_ERROR_CLASS_NAME);
        }
      }, 400);
    };
    input.addEventListener('input', onInput);
  };

  // when form is submitted, instead of the actual submission, redirecting the user to the authored anchor's href
  const initContainer = (container: HTMLElement) => {
    const form = findInstanceOf('form', HTMLFormElement, container);
    if (form === null) {
      return;
    }
    const zipCodeInput = findInstanceOf(
      ZIP_CODE_INPUT_SELECTOR,
      HTMLInputElement,
      form
    );
    const onSubmit = (e: SubmitEvent) => {
      e.preventDefault();
      // need to grab find surgeon url later
      // due to race condition with alle-redirect.js
      const surgeonUrl =
        findInstanceOf('a', HTMLAnchorElement, container)?.getAttribute(
          'href'
        ) ?? '';
      const enteredZipCode = zipCodeInput?.value ?? '';
      if (surgeonUrl && enteredZipCode && !form.querySelector('.error')) {
        const processedSurgeonUrl = new URL(
          // to make sure there are no duplicates in params
          surgeonUrl
            .replace(`&${FIND_SURGEON_URL_SEARCH_KEY}=`, '')
            .replace(`${FIND_SURGEON_URL_SEARCH_KEY}=`, '')
        );
        processedSurgeonUrl.searchParams.append(
          FIND_SURGEON_URL_SEARCH_KEY,
          enteredZipCode
        );
        const ghostLink = document.createElement('a');
        ghostLink.target = '_blank';
        ghostLink.href = processedSurgeonUrl.href;
        ghostLink.click();
        if (zipCodeInput !== null) {
          zipCodeInput.value = '';
        }
      } else if (!enteredZipCode) {
        // the default validation happens only on change event
        // creating and dispatching a change event on input field,
        // making it to invoke the validation
        zipCodeInput?.dispatchEvent(new Event('change'));
      }
    };
    form.addEventListener('submit', onSubmit);
  };

  const init = () => {
    findInstancesOf(
      `${FIND_SURGEON_CONTAINER_SELECTOR} ${ZIP_CODE_INPUT_SELECTOR}`,
      HTMLInputElement
    ).forEach(initInput);
    findInstancesOf(FIND_SURGEON_CONTAINER_SELECTOR, HTMLElement).forEach(
      initContainer
    );
  };

  if (document.readyState === 'loading') {
    document.addEventListener('DOMContentLoaded', init);
  } else {
    init();
  }
})();
