// Stylesheets
import './main.scss';

import './resources/images/favicon.png';

// Imports all component based scss files.
import './components/**/*.scss';

// Imports all component based ts files.
import './components/**/*.ts';

import { debounce } from './utils';

interface AAAEM {
  isMobile: boolean;
  isTouch: boolean;
  isAuthorMode: boolean;
  env: string;
  browser: {
    name: string;
    version: string;
  };
  // All utility functions can be found here: https://gitlab.com/venture-moonwalker/aaaem/aaaem-common/-/blob/645c2f4310c2cf0864454035850a95d28e65e68e/ui.frontend/src/global.ts
  utilities: any;
  plugins: {
    tinySlider: any;
    badgerAccordion: any;
    tabbyJs: any;
    plyr: any;
  };
}

declare global {
  interface Window {
    AAAEM: AAAEM;
    Bus: any; // event bus
    OneTrust: any;
    _tnsInstances: any; //carousel
    _tabbyInstances: any; //tabs
    _plyrInstances: any; //vimeo embed
    _badgerInstances: any; //accordion
  }
}

(() => {
  const init = () => {

    // sets cookie banner position over isi, even when isi is open
    const isi = document.querySelector('.aaaem-isi-container') as HTMLElement;
    const outputsize = () => {
      const isiBannerHeight =
        document.documentElement.style.getPropertyValue('--isiBannerHeight');
      document.documentElement.style.setProperty(
        '--isiElHeight',
        isiBannerHeight === '0px' ? '0px' : `${isi?.offsetHeight || 0}px`
      );
    }
    outputsize()
     
    new ResizeObserver(outputsize).observe(isi);

    const handleScroll = () => {
      const isiBannerHeight =
        document.documentElement.style.getPropertyValue('--isiBannerHeight');
      document.documentElement.style.setProperty(
        '--isiElHeight',
        isiBannerHeight === '0px' ? '0px' : `${isi?.offsetHeight || 0}px`
      );
    }

    window.addEventListener('scroll', debounce(handleScroll));

    // Closes for Patients dropdown on offclick
    window.addEventListener('click', (event) => {
      const target = event.target as Node;
      const dropdown = document.querySelector('.for_patients_dropdown');
      const isDropdownClick = dropdown?.contains(target);
      if (!isDropdownClick) {
        const dropdownBtn = dropdown?.querySelector('.emu-card__toggle-cta button') as HTMLButtonElement;
        if (dropdownBtn.classList.contains('js-toggle-on')) {
          const titleWrapper = dropdown?.querySelector('.emu-card__title-wrapper') as HTMLElement;
          titleWrapper?.click();
        }
      }
    })

    // close interstitial modal when confirm clicked
    document.querySelector('[data-ref="confirm-cta"] a')?.addEventListener('click', () => {
      const closeCTA = document.querySelector('[data-ref="close-cta"] button') as HTMLButtonElement;
      closeCTA?.click();
    });

    if (window.Bus) {
      // listen to events here
    }
  };
  if (document.readyState === 'loading') {
    document.addEventListener('DOMContentLoaded', init);
  } else {
    init();
  }
})();
