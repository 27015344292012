(() => {
  const init = () => {
    const $carousels = document.querySelectorAll(
      '.u-hide-even-controls-in-desktop'
    ) as NodeListOf<HTMLElement>;

    // making sure that the carousels with u-hide-even-controls-in-desktop class, when dragged shows corresponding slides
    if ($carousels?.length) {
      $carousels.forEach($carousel => {
        const carouselId = $carousel.id;
        const $navItems = $carousel.querySelectorAll(
          '.tns-nav button'
        ) as NodeListOf<HTMLButtonElement>;
        const inst = window._tnsInstances?.[carouselId];
        if (inst) {
          inst.events.on('indexChanged', e => {
            let $nextNavBtn;
            if (window.innerWidth >= 1024) {
              $navItems.forEach(($el, i) => {
                if ($el.classList.contains('tns-nav-active') && i % 2 !== 0) {
                  if (e.index > e.indexCached) {
                    $nextNavBtn = $navItems[i + 1];
                  } else {
                    $nextNavBtn = $navItems[i - 1];
                  }
                }
              });
              $nextNavBtn?.click();
            }
          });
        }
      });
    }
  };

  if (document.readyState === 'loading') {
    document.addEventListener('DOMContentLoaded', init);
  } else {
    init();
  }
})();
