(() => {
  const FOOTER_IN_VIEWPORT_CLASS_NAME = 'footer-in-viewport';
  const FOOTER_SELECTOR = 'footer.footer';

  const findInstanceOf = <T extends HTMLElement>(
    selector,
    isInstanceOf: new (...args: Array<any>) => T,
    searchOn: HTMLElement | Document = document
  ): T | null => {
    const element = searchOn.querySelector(selector);
    if (element instanceof isInstanceOf) {
      return element;
    }
    return null;
  };

  const init = () => {
    const footer = findInstanceOf(FOOTER_SELECTOR, HTMLElement);
    if (footer === null) {
      return;
    }
    const observer = new IntersectionObserver(entries => {
      if (entries.length === 0) {
        return;
      }
      const isFooterInViewport = entries[0].isIntersecting;
      if (isFooterInViewport) {
        document.documentElement.classList.add(FOOTER_IN_VIEWPORT_CLASS_NAME);
      } else {
        document.documentElement.classList.remove(
          FOOTER_IN_VIEWPORT_CLASS_NAME
        );
      }
    });
    observer.observe(footer);
  };

  if (document.readyState === 'loading') {
    document.addEventListener('DOMContentLoaded', init);
  } else {
    init();
  }
})();
