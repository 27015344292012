(() => {
  const initCustomTabBtnClick = ($customTabs: HTMLElement) => {
    let $customTabTriggers: NodeListOf<HTMLButtonElement>,
      $actualTabTriggers: NodeListOf<HTMLAnchorElement>,
      activeCustomTabTriggerClass = 'custom-tab__btn--active';

    // appending events to the custom triggers, and clicking a corresponding tab trigger programmatically.
    const appendEvents = () => {
      $customTabTriggers.forEach(($trigger, i) => {
        $trigger.addEventListener('click', () => {
          $customTabTriggers.forEach($el => {
            $el.classList.remove(activeCustomTabTriggerClass);
          });
          $trigger.classList.add(activeCustomTabTriggerClass);
          $actualTabTriggers[i]?.click?.();
        });
      });
    };

    // selects the initial tab present selected in the tab component
    const selectInitialTab = () => {
      $actualTabTriggers.forEach(($el, i) => {
        if ($el.getAttribute('aria-selected') === 'true') {
          $customTabTriggers[i]?.click?.();
        }
      });
    };

    // initializing variables used across this file
    const initVariables = () => {
      $customTabTriggers = $customTabs?.querySelectorAll(
        '.custom-tab__btn'
      ) as NodeListOf<HTMLButtonElement>;
      $actualTabTriggers = $customTabs?.querySelectorAll(
        '.aaaem-tabs__tab a'
      ) as NodeListOf<HTMLAnchorElement>;
    };

    initVariables();
    appendEvents();
    selectInitialTab();
  };

  const init = () => {
    const $customTabs = document.querySelectorAll(
      '.custom-tab__container'
    ) as NodeListOf<HTMLElement>;

    $customTabs.forEach($customTab => {
      initCustomTabBtnClick($customTab);
    });
  };

  if (document.readyState === 'loading') {
    document.addEventListener('DOMContentLoaded', init);
  } else {
    init();
  }
})();
